import React from 'react';

export default function BigSolidBubble({
  fill,
  scale,
}) {
  return (
    <svg width={scale || '147'} viewBox="0 0 147 251" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.85428 70.4776C-3.34935 52.2869 -0.707504 13.4176 51.4889 3.46623C100.723 -5.92045 108.197 3.00319 113 39.5184C117.803 76.0335 92.3175 79.6146 100.723 112.518C109.129 145.422 137.395 144.207 145 179.518C152.605 214.83 127.942 242.999 100.723 249.018C73.5044 255.037 39.0804 245.028 32.2756 207.309C25.4709 169.59 51.4889 161.966 46.2854 133.075C41.1618 100.653 13.1033 109.802 1.85428 70.4776Z" fill={fill || '#ccc'} />
    </svg>
  );
}
